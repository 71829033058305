export const initialState = {
  records: [],
  records2: [],
  isUpdate: false,
  agoisLoaded: false,

  //COATING
  nk2_index: [],
  nk2_daily: [],
  nk2_output: [],
  nk2_detail: [],
  nk2_detail_5min: [],
  nk2_2u_fibre_sensor: [],
  nk2_4u_fibre_sensor: [],
  nk2_4u_fibre_sensor_5min: [],
  nk2_4u_fibre_sensor_multiple: [],
  nk2_4u_fibre_sensor_multiple_5min: [],
  nk2_main_pressure_sensor: [],
  nk2_main_pressure_sensor_5min: [],
  nk2_main_pressure_sensor_multiple: [],
  nk2_main_pressure_sensor_multiple_5min: [],
  nk2_multipledetail: [],
  nk2_multipledetail_5min: [],
  detailsData: null,
  multipledetailsData: null,
  nk3_index: [],
  nk3_detail: [],
  nk3_detail_5min: [],
  nk3_multipledetail: [],
  nk3_multipledetail_5min: [],
  nk3_2u_fibre_sensor: [],
  nk3_4u_fibre_sensor: [],
  winding: null,
  parameter_card: null,
  nk2: null,
  nk3: null,
  settingId: null,
  settingTable: null,
  c1d1z: null,
  c1d2z: null,
  c2d1z: null,
  c2d2z: null,
  c3d1z: null,
  c3d2z: null,
  c4d1z: null,
  c4d2z: null,
  c4d3z: null,
  menu: '',
  f2u: null,

  //INKJET
  ij_latest_weight_no1: [],
  ij_latest_detail_no1: [],
  ij_index_no1: [],
  codingLatestData: [],
  ij_coding_log: [],
  //..Machine T
  machine_t: [],
  machine_tRecords: [],
  machine_tLatestData: [],
  machine_tRecordsbyhour: [],
  //..MACHINE M
  machine_m: [],
  machine_mRecords: [],
  machine_mLatestData: [],
  machine_mRecordsbyhour: [],
  //..MACHINE D
  machine_d: [],
  machine_dRecords: [],
  machine_dLatestData: [],
  machine_dRecordsbyhour: [],
  //..MACHINE C
  machine_c: [],
  machine_cRecords: [],
  machine_cLatestData: [],
  machine_cRecordsbyhour: [],
  //..MACHINE H
  machine_h: [],
  machine_hRecords: [],
  machine_hLatestData: [],
  machine_hRecordsbyhour: [],
  //PO
  po_vendor: [],
  po_edit_vendor: [],
  po_data: [],

  //ASSEMBLY
  assembly_line1: [],

  //REWINDING
  rewinding: [],
  rewinding_total: [],
};
